import store from "../redux/store";
import React, { createRef } from "react";
import moment from "moment";

const FormPdf = React.forwardRef((props,ref)=>{
	//const formRef = createRef();
	//props.formRef = createRef();
	//const ref = React.createRef();
	//let awards_in_last_three_years = []

	console.log(props.data)
    return(
					<div ref={ref} className="w-full">
						<div className="w-full justify-center flex">
							<img
							src={require("../images/unnamed.jpg")}
							className="lg:w-1/6 lg:h-1/5 md:h-2/5 md:w-2/6 sm:w-2/6 sm:h-2/6 my-4"
							/>
                  		</div>
            
							<h4 className="font-bold">Puja Committee Details</h4>
							<table className="border w-full p-5">
								<tr className="border"><td className="border w-1/2 p-1">Name:</td><td className="border w-1/2 p-1">{props.data. name }</td></tr>
								<tr className="border"><td className="border w-1/2 p-1">Puja Venue:</td><td className="border w-1/2 p-1">{props.data. address_of_puja_venue }</td></tr>
								<tr className="border"><td className="border w-1/2 p-1">PIN:</td><td className="border w-1/2 p-1">{props.data. pin }</td></tr>
								{/* <tr className="border"><td className="border w-1/2 p-1">Police Station:</td><td className="border w-1/2 p-1">{props.data. police_station }</td></tr> */}
								<tr className="border"><td className="border w-1/2 p-1">Nearest Fire Station:</td><td className="border w-1/2 p-1">{props.data. nearest_fire_station }</td></tr>
								<tr className="border"><td className="border w-1/2 p-1">Nearest Hospital:</td><td className="border w-1/2 p-1">{props.data. nearest_hospital }</td></tr>
								<tr className="border"><td className="border w-1/2 p-1">District:</td><td className="border w-1/2 p-1">{props.data. district }</td></tr>
								<tr className="border"><td className="border w-1/2 p-1">Ward No:</td><td className="border w-1/2 p-1">{props.data. ward_no }</td></tr>
								<tr className="border"><td className="border w-1/2 p-1">Type of Puja:</td><td className="border w-1/2 p-1">{props.data. type_of_puja }</td></tr>
								<tr className="border"><td className="border w-1/2 p-1">Puja Name:</td><td className="border w-1/2 p-1">{props.data. puja_name }</td></tr>
							</table>
						
							    <br/>
							
							<h4 className="font-bold">President / Secretary Details</h4> 
							<table className="border w-full p-5">
								<tr className="border"><td className="border w-1/2 p-1">Name:</td><td className="border w-1/2 p-1">{props.data. president_secretary_name }</td></tr>
								<tr className="border"><td className="border w-1/2 p-1">Father Name:</td><td className="border w-1/2 p-1">{props.data. president_secretary_father_name }</td></tr>
								{/* <tr className="border"><td className="border w-1/2 p-1">Age:</td><td className="border w-1/2 p-1">{props.data. president_secretary_age }</td></tr> */}
								<tr className="border"><td className="border w-1/2 p-1">Address:</td><td className="border w-1/2 p-1">{props?.data?. president_secretary_address || props?.data?.president_line_one }</td></tr>
								<tr className="border"><td className="border w-1/2 p-1">PIN:</td><td className="border w-1/2 p-1">{props.data. president_secretary_pin == -1 ? '' : props.data.president_secretary_pin }</td></tr>
								{/* <tr className="border"><td className="border w-1/2 p-1">Occupation:</td><td className="border w-1/2 p-1">{props.data. president_secretary_occupation }</td></tr> */}
								<tr className="border"><td className="border w-1/2 p-1">Mobile No:</td><td className="border w-1/2 p-1">{props.data. president_secretary_mobile }</td></tr>
								<tr className="border"><td className="border w-1/2 p-1">Whatsapp No:</td><td className="border w-1/2 p-1">{props.data. president_secretary_wa_no }</td></tr>
								<tr className="border"><td className="border w-1/2 p-1">Email:</td><td className="border w-1/2 p-1">{props.data. president_secretary_email }</td></tr>
							</table>
							<br/>
							
							<h4 className="font-bold">Idol Maker Details</h4> 
							<table className="border w-full p-5">
								<tr className="border"><td className="border w-1/2 p-1">Name:</td><td className="border w-1/2 p-1">{props.data. idol_maker_name }</td></tr>
								{/* <tr className="border"><td className="border w-1/2 p-1">Address:</td><td className="border w-1/2 p-1">{(props.data. idol_maker_address) !=="undefined undefined"? props.data.idol_maker_address : "" }</td></tr> */}
								{/* <tr className="border"><td className="border w-1/2 p-1">PIN:</td><td className="border w-1/2 p-1">{props.data. idol_maker_pin == -1 ? '' : props.data.idol_maker_pin }</td></tr> */}
								<tr className="border"><td className="border w-1/2 p-1">Mobile No:</td><td className="border w-1/2 p-1">{props.data. idol_maker_mobile }</td></tr>
								<tr className="border"><td className="border w-1/2 p-1">Whatsapp No:</td><td className="border w-1/2 p-1">{props.data. idol_maker_wa_no }</td></tr>
								{/* <tr className="border"><td className="border w-1/2 p-1">Email:</td><td className="border w-1/2 p-1">{props.data. idol_maker_email }</td></tr> */}
								{/* <tr className="border"><td className="border w-1/2 p-1">Purchase count:</td><td className="border w-1/2 p-1">{props.data. idol_maker_purchase_count }</td></tr> */}
							</table>
							<br/>

								{/* Add CATERER Section in API Sersponse Body.
							 	Author: Biswarup Das.
								Date: 04-08-2022. */}	
							<h4 className="font-bold">CATERER Details</h4> 
							<table className="border w-full p-5">
								<tr className="border"><td className="border w-1/2 p-1">Name:</td><td className="border w-1/2 p-1">{props.data. caterer_name }</td></tr>
								{/* <tr className="border"><td className="border w-1/2 p-1">Address:</td><td className="border w-1/2 p-1">{(props.data. caterer_address) !=="undefined undefined"? props.data.caterer_address : "" }</td></tr> */}
								{/* <tr className="border"><td className="border w-1/2 p-1">PIN:</td><td className="border w-1/2 p-1">{props.data. caterer_pin == -1 ? '' : props.data.caterer_pin }</td></tr> */}
								<tr className="border"><td className="border w-1/2 p-1">Mobile No:</td><td className="border w-1/2 p-1">{props.data. caterer_mobile }</td></tr>
								<tr className="border"><td className="border w-1/2 p-1">Whatsapp No:</td><td className="border w-1/2 p-1">{props.data. caterer_wa_no }</td></tr>
								{/* <tr className="border"><td className="border w-1/2 p-1">Email:</td><td className="border w-1/2 p-1">{props.data. caterer_email }</td></tr> */}
								{/* <tr className="border"><td className="border w-1/2 p-1">Purchase count:</td><td className="border w-1/2 p-1">{props.data. idol_maker_purchase_count }</td></tr> */}
							</table>
							<br/><br/><br/>
							
							<h4 className="font-bold">Sound System Supplier Details</h4> 
							<table className="border w-full p-5">
								<tr className="border"><td className="border w-1/2 p-1">Name:</td><td className="border w-1/2 p-1">{props.data. sound_system_supplier_name }</td></tr>
								{/* <tr className="border"><td className="border w-1/2 p-1">Address:</td><td className="border w-1/2 p-1">{(props.data. sound_system_supplier_address) !=="undefined undefined"? props.data.sound_system_supplier_address : "" }</td></tr> */}
								{/* <tr className="border"><td className="border w-1/2 p-1">PIN:</td><td className="border w-1/2 p-1">{props.data. sound_system_supplier_pin == -1 ? '' : props.data.sound_system_supplier_pin }</td></tr> */}
								<tr className="border"><td className="border w-1/2 p-1">Mobile No:</td><td className="border w-1/2 p-1">{props.data. sound_system_supplier_mobile }</td></tr>
								<tr className="border"><td className="border w-1/2 p-1">Whatsapp No:</td><td className="border w-1/2 p-1">{props.data. sound_system_supplier_wa_no }</td></tr>
								{/* <tr className="border"><td className="border w-1/2 p-1">Email:</td><td className="border w-1/2 p-1">{props.data. sound_system_supplier_email }</td></tr> */}
								{/* <tr className="border"><td className="border w-1/2 p-1">CIN No:</td><td className="border w-1/2 p-1">{props.data. sound_system_supplier_cin_no }</td></tr> */}
								{/* <tr className="border"><td className="border w-1/2 p-1">No of amplifiers used:</td><td className="border w-1/2 p-1">{props.data. sound_system_supplier_amplifier_count }</td></tr> */}
								{/* <tr className="border"><td className="border w-1/2 p-1">No of sound limiters used:</td><td className="border w-1/2 p-1">{props.data. sound_system_supplier_limiter_count }</td></tr> */}
							</table>
							<br/>
							
							<h4 className="font-bold">Decorator Details</h4> 
							<table className="border w-full p-5">
								<tr className="border"><td className="border w-1/2 p-1">Name:</td><td className="border w-1/2 p-1">{props.data. decorator_name }</td></tr>
								{/* <tr className="border"><td className="border w-1/2 p-1">Address:</td><td className="border w-1/2 p-1">{(props.data. decorator_address) !=="undefined undefined"? props.data.decorator_address : "" }</td></tr> */}
								{/* <tr className="border"><td className="border w-1/2 p-1">PIN:</td><td className="border w-1/2 p-1">{props.data. decorator_pin == -1 ? '' : props.data.decorator_pin }</td></tr> */}
								<tr className="border"><td className="border w-1/2 p-1">Mobile No:</td><td className="border w-1/2 p-1">{props.data. decorator_mobile }</td></tr>
								<tr className="border"><td className="border w-1/2 p-1">Whatsapp No:</td><td className="border w-1/2 p-1">{props.data. decorator_wa_no }</td></tr>
								{/* <tr className="border"><td className="border w-1/2 p-1">Email:</td><td className="border w-1/2 p-1">{props.data. decorator_email }</td></tr> */}
								{/* <tr className="border"><td className="border w-1/2 p-1">CIN No:</td><td className="border w-1/2 p-1">{props.data. decorator_cin }</td></tr> */}
							</table>
							<br/>
							
							{/* <h4 className="font-bold">Awards in Previous Years</h4> 
							{props?.data.awards_in_last_three_years?.length!==0 ? <table className="border w-full p-5">
							
							{props.data.awards_in_last_three_years?.map((award,index) => {
								return <tr className="border"><td className="border w-1/2 p-1">{index+1}:</td><td className="border w-1/2 p-1">{award}</td></tr>
							})}
							</table> : <h3>"No Awards Data Provided"</h3>} */}
							<br/>
							
							<h4 className="font-bold">Immersion Details</h4> 
							<table className="border w-full p-5">
								{/* <tr className="border"><td className="border w-1/2 p-1">Proposed Date:</td><td className="border w-1/2 p-1">{moment(props.data.immersion_proposed_date).format("DD-MM-YYYY") }</td></tr>
								<tr className="border"><td className="border w-1/2 p-1">No of Vehicles to Be Used:</td><td className="border w-1/2 p-1">{props.data. immersion_vehicle_count }</td></tr>
								<tr className="border"><td className="border w-1/2 p-1">Name of the Agency for Clearance of Waste Materials:</td><td className="border w-1/2 p-1">{props.data. immersion_waste_clearance_agency_name }</td></tr> */}
								<tr className="border"><td className="border w-1/2">Whether fire work will be used:</td><td className="border w-1/2">{props.data. immersion_fireworks_usage ? 'Yes' : 'No' }</td></tr>
							</table>
							<br/>
							
							{/* <h4 className="font-bold">Fireworks Supplier Details</h4> 
							<table className="border w-full p-5">
								<tr className="border"><td className="border w-1/2">Name:</td><td className="border w-1/2">{props.data. fireworks_supplier_name }</td></tr>
								<tr className="border"><td className="border w-1/2">Address:</td><td className="border w-1/2">{(props.data. fireworks_supplier_address) !=="undefined undefined"? props.data.fireworks_supplier_address: "" }</td></tr>
								<tr className="border"><td className="border w-1/2">PIN:</td><td className="border w-1/2">{props.data. fireworks_supplier_pin == -1 ? '' : props.data.fireworks_supplier_pin }</td></tr>
								<tr className="border"><td className="border w-1/2">Mobile No:</td><td className="border w-1/2">{props.data. fireworks_supplier_mobile }</td></tr>
								<tr className="border"><td className="border w-1/2">Email:</td><td className="border w-1/2">{props.data. fireworks_supplier_email }</td></tr>
							</table>
							<br/> */}
							
							{/* <h4 className="font-bold">Proposed Environment Protection Adoption</h4> 
							<table className="border w-full p-5">
								<tr className="border"><td className="border w-1/2">Wet waste separation and disposal:</td><td className="border w-1/2">{props.data. wwsd ? 'Yes' : 'No' }</td></tr>
								<tr className="border"><td className="border w-1/2">Dry waste separation and disposal:</td><td className="border w-1/2">{props.data. dwsd ? 'Yes' : 'No' }</td></tr>
								<tr className="border"><td className="border w-1/2">Plastic waste separation and disposal:</td><td className="border w-1/2">{props.data. pwsd ? 'Yes' : 'No' }</td></tr>
								<tr className="border"><td className="border w-1/2">E-waste separation and disposal:</td><td className="border w-1/2">{props.data. ewsd ? 'Yes' : 'No' }</td></tr>
								<tr className="border"><td className="border w-1/2">Bio-Medical waste separation and disposal:</td><td className="border w-1/2">{props.data. bmwsd ? 'Yes' : 'No' }</td></tr>
								<tr className="border"><td className="border w-1/2">Awareness Message and banners:</td><td className="border w-1/2">{props.data. awareness_message_banners ? 'Yes' : 'No' }</td></tr>
								<tr className="border"><td className="border w-1/2">Name of the agency /Person managing waste management:</td><td className="border w-1/2">{props.data. waste_management_agency }</td></tr>
								<tr className="border"><td className="border w-1/2">Non-Bio-degradable materials used in decoration:</td><td className="border w-1/2">{props.data. mbdm_used_in_decoration ? 'Yes' : 'No' }</td></tr>
								<tr className="border"><td className="border w-1/2">Sufficient no. of waste disposal bins used in Pandal vicinity:</td><td className="border w-1/2">{props.data. suf_no_waste_bins ? 'Yes' : 'No' }</td></tr>
								<tr className="border"><td className="border w-1/2">Sanitization measures:</td><td className="border w-1/2">{props.data. sanitization_measures ? 'Yes' : 'No' }</td></tr>
								<tr className="border"><td className="border w-1/2">Social distancing measures:</td><td className="border w-1/2">{props.data. social_distancing_measures ? 'Yes' : 'No' }</td></tr>
								<tr className="border"><td className="border w-1/2">Awareness about COVID-19:</td><td className="border w-1/2">{props.data. covid19_awareness ? 'Yes' : 'No' }</td></tr>
							</table>
							<br/> */}
							
						<h3 className="font-bold text-center">UNDERTAKING</h3>
						<br/>
							<p>I, {props.data. president_secretary_name}, son/daughter/wife of  {props.data. president_secretary_father_name}, residing at  {props.data.president_secretary_address}, do here by undertake as follows:</p>
							
							<p>1. That, I am the President/Secretary of  {props.data.name} (name of the Puja Commitee / Barwari) having it’s address at  {props.data.address_of_puja_venue} ,Pin- {props.data.pin} and organizing a {props.data.type_of_puja} at {props.data.address_of_puja_venue}, Pin - {props.data.pin} in the year 2023.</p>
							

							 {/* Old Under taking Text.  */}
							{/* <p>2. That, I, on behalf of my organization as mentioned above, undertake that my organization will comply with the following norms strictly:-</p>
							<p>2.1. In terms of order dated 06.10.2017 of the Hon’ble National Green Tribunal, Eastern Zone, passed in connection with OA No: 51/2016 (Ambar Nath Sengupta Vs State of West Bengal & Ors.)</p>
							<ul>
							<li>No Plaster of Paris will be used in Idol making.</li>
							<li>No synthetic paint or colour containing toxic heavy metals like mercury, cadmium, arsenic, lead and chromium shall be used in Idol making.</li>
							<li>The height of idol shall be limited to 20 ft.</li>
							<li>The height of the super structure shall be limited to 20 ft.</li>
							</ul>
							
							<p>2.2. As fixed up by the West Bengal Pollution Control Board, for organizing a community Durga Puja/Kali Puja/other Puja in accordance with Environment (Protection) Act, 1986 read with the Noise Pollution (Regulation & Control) Rules, 2000. Norms are as follows:</p>
							<ul>
							<li>That my organization will use and operate microphones after obtaining permission from the concerned police authorities or district authorities and will abide by all the terms and conditions fixed up by the police authorities or the district authorities at the time of granting such permission.</li>
							<li>No microphone shall be used or operated within ‘Silence Zone’ areas, i.e. within meters of any educational institution, hospital, nursing home or court areas in accordance with the Acts and Rules as mentioned above. During holidays and on working days after office hours, the areas of educational institutions and courts are not treated as ‘Silence Zones’).</li>
							<li>That, no noise making fireworks, which generates noise more than distance of dB(A) impulse from a meter from the point of bursting shall be used.</li>
							<li>That no Disk Jockey Set (D.J) will be used during the procession of immersion of idols.</li>
							</ul>
							
							<p>2.3. Microphones will be used or operated only fixing ‘Sound Limiter’ with the amplifier system in accordance with the order passed by the Hon’ble High Court, Calcutta, in connection with matter No. 4303 (W) of 1995 (Om Birangana Religious Society, Petitioner – Versus - State of West Bengal & Ors., Respondents), order of the State Board dated- 27.08.2004, and orders passed by the Hon’ble National green Tribunal in connection with OA No.158/2016/EZ, for maintaining ambient noise standard as specified for different areas as per the aforementioned Acts and Rules.</p>
							
							<p>2.4. Provisions of the West Bengal Prevention and Control of Water Pollution (Procedure for Immersion of Idol after Pujas) Rules, 2018. That the following norms will be complied with strictly, during the immersion process:</p>
							<ul>
							<li>The offerings, like flowers and leaves etc. will be deposited in the Bins, Places or Pits as arranged by the concerned local authorities.</li>
							<li>Immersion of idols will take place in accordance with the scheduled dates fixed up by the Police Authorities or by the District Authorities as the case may be.</li>
							</ul>
							
							<p>3. That in case of violation of any of the above-mentioned norms, the West Bengal Pollution Control Board and other authorities concerned under the statute shall be at liberty to take necessary penal action in accordance with law, against the community puja committee by imposing responsibility upon the President/Secretary of the Puja Committee.</p>
							
							<p>4. I hereby declare that the details furnished above are true and correct to the best of my knowledge and belief and I undertake to inform you of any changes therein, immediately. In case any of the above information is found to be false or untrue or misleading or misrepresenting, I am aware that I may be held legally liable for it.</p>
							
							<p>5. That in case of violation of any of the above-mentioned norms, the West Bengal Pollution Control Board and other authorities concerned under the statute shall be at liberty to take necessary penal action in accordance with law, against the community puja committee by imposing responsibility upon the President/Secretary of the Puja Committee.</p>
							
							<br/><br/>
							
							<p>I agree to the above UNDERTAKING.</p>
							
							<br/><br/><br/><br/>
							
							<p>Signature with date: <u>{props.data. president_secretary_name }</u></p>
							<p>Address: <u>{props.data. president_secretary_address }</u></p>
							<p>P.S.: <u>{props.data. police_station }</u></p>
							<p>District: <u>{props.data. district }</u></p>
							<p>Mobile No: <u>{props.data. president_secretary_mobile }</u></p>
							<p>Email: <u>{props.data. president_secretary_email }</u></p> */}
							

								{/* New Untertaking Message Body.
								Author: Biswarup Das.
								Date: 18/08/2022.	  */}
							<p>2. That, I, on behalf of my organization as mentioned above, undertake that my organization will strictly comply with the following: - </p>
							<p>2.1. In conformity with order dated 06.10.2017 of the Hon’ble National Green Tribunal, Eastern Zone, passed in connection with OA No: 51/2016 (Ambar Nath Sengupta Vs State of West Bengal & Ors.) and letter of the Central Pollution Control Board (CPCB), Delhi dated 17/02/2022 for effective implementation of the “Revised Guidelines for Idol Immersion, May 2020 </p>
							<ul>
								<li className="ml-2">* Making of Idols to be done using clay and biodegradable materials only.</li>
								<li className="ml-2">* No Plaster of Paris to be used in Idol making. </li>
								<li className="ml-2">* The use of natural dyes/colours and biodegradable materials is to be explored for decoration of idol.</li>
								<li className="ml-2">* No synthetic paint or colour containing toxic heavy metals like mercury, cadmium, arsenic, lead and chromium to be used in Idol making.</li>
								<li className="ml-2">* The height of idol to be limited to 20 ft. </li>
								<li className="ml-2">* The height of the super structure to be limited to 40ft. </li>
							</ul>
							
							<p>2.2. Provisions of the West Bengal Prevention and Control of Water Pollution (Procedure for Immersion of Idol after Pujas) Rules, 2018.</p>
							<ul>
							<li className="ml-2">* That my organization will use and operate microphones after obtaining permission from the concerned police authorities or district authorities and will abide by all the terms and conditions fixed up by the police authorities or the district authorities at the time of granting such permission.</li>
							<li className="ml-2">* No microphone shall be used or operated within ‘Silence Zone’ areas, i.e. within meters of any educational institution, hospital, nursing home or court areas in accordance with the Acts and Rules as mentioned above. During holidays and on working days after office hours, the areas of educational institutions and courts are not treated as ‘Silence Zones’).</li>
							<li className="ml-2">* That, no noise making fireworks, which generates noise more than distance of dB(A) impulse from a meter from the point of bursting shall be used.</li>
							<li className="ml-2">* That no Disk Jockey Set (D.J) will be used during the procession of immersion of idols.</li>
							</ul>
							
							<p>2.3. Microphones to be used or operated fixing ‘Sound Limiter’ with the amplifier system in accordance with the order passed by the Hon’ble High Court, Calcutta, in connection with matter No. 4303 (W) of 1995 (Om Birangana Religious Society, Petitioner – Versus - State of West Bengal & Ors., Respondents), as per Order No. 1853 – 4K-5/2004 dated 27.08.2004 of the State Board, and subsequent Notification Nos. EN/238/3C-65/2019(Part-1) & EN/507/3C-65/2019(Part-1) dated 03/02/2020 & 04/03/2020 respectively of the Department of Environment, Govt. of West Bengal regarding usage of audio systems/microphones fitted with sound limiting device by manufacturers and traders of audio systems/microphones and orders passed by the Hon’ble National Green Tribunal in connection with OA No.158/2016/EZ from time to time,  for maintaining ambient noise standard as specified for different areas as per the relevant Acts and Rules.</p>
							
							{/* <p>2.4. Norms, as fixed up by the West Bengal Pollution Control Board, for organizing a community Durga Puja / Kali Puja 2022 in accordance with Environment (Protection) Act, 1986 read with the Noise Pollution (Regulation & Control) Rules, 2000 and subsequent amendments till date, which are:</p> */}
								{/* Remove Kali Puja.
								Author: Biswarup Das.
								Date: 29-08-2022.	 */}
							<p>2.4. Norms, as fixed up by the West Bengal Pollution Control Board, for organizing a community Durga Puja / Kali Puja 2022 in accordance with Environment (Protection) Act, 1986 read with the Noise Pollution (Regulation & Control) Rules, 2000 and subsequent amendments till date, which are:</p>
							<ul>
								<li className="ml-2">* Microphones to be used and operated after obtaining permission from the concerned police authorities or district authorities and to abide by all the terms and conditions fixed up by the police authorities or the district authorities at the time of granting such permission.</li>
								<li className="ml-2">* No microphone to be used or operated within ‘Silence Zone’ areas, i.e. within 100 meters of any educational institution, hospital, nursing home or court areas in accordance with the Acts and Rules as mentioned above. \
								(During holidays and on working days after office hours, the areas of educational institutions and courts are not treated as ‘Silence Zones’).</li>
								<li className="ml-2">* That, no noise making fireworks, which generates noise more than 90 dB(A) impulse from a distance of 5 meter from the point of bursting shall be used. </li>
								<li className="ml-2">* No Disk Jockey Set (D.J) to be used during the procession of immersion of idols.</li>
							</ul>

							<p>2.5. Order of the State Board dated 26/10/2021 issued in compliance with the Hon’ble Supreme Court orders vide judgement dated 23/10/2018 in W.P (C) No.728 of 2015, directions of the Hon’ble National Green Tribunal in O.A. No 249/2020 dated 09/11/2020 and 01/12/2020, by ensuring complete ban on the sale and bursting of all kinds of firecrackers, except green crackers. Only green crackers are to be sold and bursting of such crackers shall be allowed for (i) two hours during Deepawali festival from 8 p.m. to 10 p.m. ii) two hours during Chatt Puja from 6 a.m. to 8 a.m. iii) 35 minutes during Christmas & New years’ Eve from 11:55 p.m. to 12:30 a.m.\
							Other than the above specified festivals, prior permission of the District Magistrates/Commissioners of Police/Superintendents of Police shall be taken for use of only green crackers for a limited period (not more than two hours). </p>

							<p>2.6. Provisions of the Plastic Waste Management Rules,2016, as amended, including abiding with the following directions:</p>
							<ul>
								<li className="ml-2">* No polystyrene (thermocol) to be used for decoration.</li>
								<li className="ml-2">* No plastic or PVC banners less than 100 microns to be used.</li>
							</ul>
							
							<p>3. Also, I shall ensure compliance of the following norms strictly, during the immersion process:</p>

							<p>3.1. The offerings, like flowers and leaves etc. will be deposited in the Bins, Places or Pits as arranged by the concerned local authorities on the banks of rivers, ponds, water bodies. </p>
							<p>3.2. Immersion of idols will take place in accordance with the scheduled dates fixed up by the Police Authorities or by the District Authorities as the case may be. </p>
							
							<p>4. Further, I have no objection to the use of the WhatsApp phone numbers of President/Secretary, Idol Maker, Sound System Supplier, Decorator whose details have been provided in this GREEN PUJA form by West Bengal Pollution Control Board.</p>
							
							<p>5. I hereby declare that the details furnished above are true and correct to the best of my knowledge and belief and I undertake to inform you of any changes therein, immediately. In case any of the above information is found to be false or untrue or misleading or misrepresenting, I am aware that I may be held legally liable for it. </p>
							
							<p>6. I hereby also declare that the WBPCB GREEN PUJA online form at the following link https://kolkata-greenpuja.wbpcb.gov.in/ shall be submitted within 21st October 2023.	</p>

							<p>7. Further, in case of violation of any of the above-mentioned norms, the West Bengal Pollution Control Board and other authorities concerned under the statute shall be at liberty to take necessary penal action against the community puja committee in accordance with law, by imposing responsibility upon the President/Secretary of the Puja Committee. </p>

							<br/><br/>
							
							<p>I agree to the above UNDERTAKING.</p>
							
							<br/><br/>
							
							<p>Signature with date: <u>{props.data. president_secretary_name }</u> &nbsp;&nbsp;&nbsp; <u>{moment().format("DD-MM-YYYY")}</u></p>
							<p>Address: <u>{props.data. president_line_one }</u></p>
							{/* <p>P.S.: <u>{props.data. police_station }</u></p> */}
							<p>District: <u>{props.data. district }</u></p>
							<p>Mobile No: <u>{props.data. president_secretary_mobile }</u></p>
							<p>Email: <u>{props.data. president_secretary_email }</u></p>
							
            </div>
    )
})
export default FormPdf;